import React from "react";
import { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { db } from "../firebase";
import { getDocs, collection } from "firebase/firestore/lite";
import Loading from "../components/loading/Loading";
import Intro from "../components/top_section/Intro";
import ProviderCard from "../components/main/Main";
import "../components/header/header.css";

function Results() {
  const [date, setDate] = useState();
  const [name, setName] = useState();
  const [recommendations, setRecommendations] = useState();
  const [tagList, setTagList] = useState();
  const [location, setLocation] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

// getting location of coming traffic

  // const prevUrl = document.referrer
  // console.log(prevUrl, 'prevUrl')

  // get subscriber id
  let userId = window.location.search.substring(4);


  useEffect(() => {
    let onlyTags = [];
    // fetch subscribers answers
    async function fetchData() {
      let response = await fetch(
        `https://us-central1-sln-client.cloudfunctions.net/getResponses`
      );
      let data = await response.json();
      let temp = data.items.filter((item) => {
        if (item.hidden.id === userId) {
          return true;
        } else {
          return false;
        }
      });

      console.log(temp, 'Subscriber?')
      // find the username
      let tempName = temp[0].answers[0].text.split(" ")[0];
      // console.log(tempName);
      setName(tempName);

      // find the date of the response submission
      let tempDate = temp[0].submitted_at;
      tempDate = new Date(tempDate);
      setDate(
        tempDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );

      // find the subscriber tags
      temp[0].variables
        .filter((tag) => {
          // if (tag.number > 0 && tag.key !== "score") {
          if (tag.key !== "score") {
            return true;
          } else {
            return false;
          }
        })
        .forEach((tag) => {
          onlyTags.push(tag.key);
        });
      // added care_coordinator tag to show it for every subscriber
      onlyTags.push("care_coordination");


      // !!!!!!!!!!!!!!!!!!!! replace  tags[0] with what is your address field from the questionnairie
      // geocode api - we are getting subscriber's location
      async function fetchGeocode() {
        let response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${temp[0].tags[0]}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
        );
        let data = await response.json();
        console.log(data, 'looking for data')
        let geocode = data.results[0].geometry.location;
        setLocation(geocode);
       
      }
      await fetchGeocode();
    }

    //getting providers data
    async function get(db) {
      const providersRef = collection(db, "providers");
      const providersSnapshot = await getDocs(providersRef);
      let provList = providersSnapshot.docs.map((doc) => doc.data());
      return provList;
    }

    const init = async () => {
      await fetchData();

      // find recommended list of providers (in accordance with matching tags)
      let providerList = await get(db);
      // console.log(providerList, "my providers");
      let tempRec = [];

      providerList.forEach((provider) => {
        // if (provider.status === "Active" && provider.tags) {
        if (provider.tags) {
          onlyTags.forEach((tag) => {
            if (
              provider.tags.includes(tag) &&
              tempRec.filter(
                (tempRecItem) =>
                  tempRecItem.companyName === provider.companyName
              ).length < 1
            ) {
              return tempRec.push(provider);
            }
          });
        }
      });

      console.log("tempRec", tempRec);

      // create list of tags to group providers

      let tempTagList = [];
      tempRec.forEach((tempRec) => {
        tempRec.tags.forEach((tag) => {
          tempTagList.push(tag);
        });
      });

      // remove duplicates from the list of tags
      let uniquetempTagList = tempTagList.filter((c, index) => {
        return tempTagList.indexOf(c) === index;
      });

      // compare onlyTags(which are subscribers tags) with uniquetempTagList(which are unique providers tags)

      let finalTagList = onlyTags.filter(
        (i) => uniquetempTagList.indexOf(i) !== -1
      );
      setTagList(finalTagList);
      setRecommendations(tempRec);
      setIsLoading(false);
    };
    init();
     // eslint-disable-next-line
  }, []);

//   return (
    
//     prevUrl.includes('scroll') ? ( 
//     <>
//      {isLoading ? (
//        <Loading />
//      ) : (
//        <>
//          <div className="wrapper" ref={componentRef}>
//            <header>
//              <img src="assets/logo.png" alt="Scroll" />
//              <div className="header_date_and_print">
//                <p>Report generated {date}</p>
//                <button onClick={handlePrint}>Save/Print</button>
//              </div>
//            </header>
//            <Intro name={name} />
//            {recommendations && (
//              <ProviderCard
//                recommendations={recommendations}
//                tagList={tagList}
//                location={location}
//                date={date}
//              />
//            )}
//          </div>
//        </>
//      )}
//    </>)   
//    : 
//     (window.location.replace("https://scroll.care/account/login"))   
//  );

  return (
    <>
     {isLoading ? (
       <Loading />
     ) : (
       <>
         <div className="wrapper" ref={componentRef}>
           <header>
             <img src="assets/logo.png" alt="Scroll" />
             <div className="header_date_and_print">
               <p>Report generated {date}</p>
               <button onClick={handlePrint}>Save/Print</button>
             </div>
           </header>
           <Intro name={name} />
           {recommendations && (
             <ProviderCard
               recommendations={recommendations}
               tagList={tagList}
               location={location}
               date={date}
             />
           )}
         </div>
       </>
     )}
   </>
   )   

}
export default Results;
