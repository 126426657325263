import React, { Fragment, useContext } from "react";
// import Navigate from react-router-dom  (Redirect To Internal Route)
import { Routes, Route } from "react-router-dom";

import { AuthContext } from "../Auth";
// pages
import Home from "../pages/Home";
import Results from "../pages/ResultsFirst";
import ResultsSecond from "../pages/ResultsSecond";
import ResultsThird from "../pages/ResultsThird";
import ViewMore from "../pages/ViewMore";
// test route (for internal route)
// import TestPublicRoute from '../pages/TestPublicRoute';

// components
import Loading from "../components/loading/Loading";

const Router = () => {
  const { currentUser, pending } = useContext(AuthContext);

  function ExternalRoute() {
    window.location.href = "https://scroll.care/subscriber-home";
    return null;
  }

  return pending === true ? (
    <Loading />
  ) : (
    <Routes>
      {/* Public Routes */}

      {/* <Route exact path="/test" element={<TestPublicRoute />} /> */}

      {currentUser ? (
        <Fragment>
          <Route exact path="/" element={<Home />} />
          <Route exact path="care-plan-1" element={<Results />} />
          <Route exact path="care-plan-2" element={<ResultsSecond />} />
          <Route exact path="care-plan-3" element={<ResultsThird />} />
          <Route exact path="view-more" element={<ViewMore />} />
        </Fragment>
      ) : (
        // Redirect To Internal Route
        // <Route path="*" element={<Navigate replace to="/test" />} />

        // Redirect To External Route
        <Route path="*" element={<ExternalRoute />} />
      )}
    </Routes>
  );
};

export default Router;
