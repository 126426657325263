import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ViewMoreGroupIntro from "../components/main/group_intro/ViewMoreGroupIntro";

const ViewMore = () => {
  const location = useLocation();
  const date = location.state.data;
  const { tag, description, tags, providers } = location.state;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="wrapper" ref={componentRef}>
      <header>
        <img src="assets/logo.png" alt="Scroll" />
        <div className="header_date_and_print">
          <p>Report generated {date}</p>
          <button onClick={handlePrint}>Save/Print</button>
        </div>
      </header>
      <main>
        <ul>
          <ViewMoreGroupIntro
            tag={tag}
            description={description}
            tags={tags}
            providers={providers}
          />
        </ul>
       
      </main>
    </div>
  );
};

export default ViewMore;
