import React from 'react'

function ExternalRoute() {
    window.location.href = "https://scroll.care/subscriber-home";
    return null;
  }
const Home = () => {

    return (
      <ExternalRoute />

    );
}

export default Home