import React from "react";
import "./card.css";

const Card = ({ providers, tag, tags }) => {
  return (
    <div className="group_card_container">
      {providers
        .filter((provider) => provider.tags.includes(tag))
        .slice(0, 4)
        .map((provider) => {
          function formatPhoneNumber(phoneNumber) {
            let cleaned = ("" + phoneNumber).replace(/\D/g, "");
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              return ["(", match[2], ") ", match[3], "-", match[4]].join("");
            }
            return "-";
          }

          // let providerUrl = "https://scroll.care/provider-catalog/p/" + `${provider.slug}`;
          let providerUrl = `https://scroll.care/provider-catalog/p/${provider.slug}`;


          let source = "";
          // /* uncomment it in case of displaying any of providers images(logo or hero img) */ 

          /* if (provider.img2) {
              source = provider.img2;
                } else if (provider.img1) 
                  source = provider.img1;
                    else
                      source = "https://storage.googleapis.com/sln-client.appspot.com/assets/..."; */


          if (provider.img2) {
            source = provider.img2;
          } else
            source =
              "https://storage.googleapis.com/sln-client.appspot.com/assets/180x180.jpg";

          return (
            <div
              key={Math.random()}
              style={
                tag === "care_coordination"
                  ? { background: "#fff", border: "0.75px solid #3DC0BB" }
                  : { background: "#e1e9f3" }
              }
              className="card"
            >
              <div className="card_header">
                <div className="card_header_logo">
                  <img src={source} alt="headshot" />
                  {/* <p>Photo</p> */}
                </div>

                <div className="card_header_creds">
                  <h3>{provider.companyName}</h3>
                  <p>{provider.address}</p>
                </div>
              </div>

              <div className="card_contacts">
                <p>{provider.email}</p>
                <p>{formatPhoneNumber(provider.phone)}</p>
              </div>

              <div className="card_btn">
                <a href={providerUrl}>View provider page</a>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Card;
