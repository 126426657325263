import React, { useState, useEffect } from "react";
import { db } from "./firebase";

import { isExpired } from "react-jwt";

import { collection, getDocs, query, where } from "firebase/firestore/lite";

import { publicIp } from "public-ip";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  const usersCollectionRef = collection(db, "auth_users");

  useEffect(() => {
    const getAuthusers = async () => {

      const q = query(usersCollectionRef, where("ip", "==", await publicIp()));
      const current = await getDocs(q);

      if (current.docs && current.docs[0]) {
        const isMyTokenExpired = isExpired(current.docs[0].data().token);
        if (!isMyTokenExpired) {
          setCurrentUser({ id: current.docs[0].id, ...current.docs[0].data() });
        }
        setPending(false);
      } else {
        setPending(false);
      }
    };

    getAuthusers();
 // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        pending,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
