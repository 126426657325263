import React from "react";

import "./group_intro.css";
import ViewMoreCard from "../card/ViewMoreCard";


const ViewMoreGroupIntro = ({ tag, tags, description, providers }) => {
  let tagName = tag.charAt(0).toUpperCase() + tag.slice(1).replace("_", " ");


  return (

    <li key={`section-${tag}`} className="group">
      <div className="group_intro_title">
        {tag === "care_coordination" ? (
          <h2>Don't want to do it all yourself?</h2>
        ) : (
          <h2>{tagName}</h2>
        )}
        <hr />
      </div>
      <div className="group_intro_description">
        <p>
          {description}         
        </p>
       
      </div>
      <ViewMoreCard providers={providers} tag={tag} tags={tags} />
      
    </li>
  );
};

export default ViewMoreGroupIntro;
