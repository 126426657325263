import React from "react";
import "./intro.css";

const Intro = ({ name }) => {
  return (
    <section className="top_section">
      <div className="top_section_name">
        <h1>{name}'s care plan</h1>
        <img src="assets/icon.png" alt="Note's icon" />
        <hr />
      </div>
      <div className="top_section_description">
      <p>
        Based on the answers provided within the questionnaire, scroll
        recommends these providers to help with {name}'s care needs.
      </p>
      </div>
    </section>
  );
};

export default Intro;
