import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyABMpjTq5hlTSPsknxaBUTgmUvNGgi_U-g",
    authDomain: "sln-client.firebaseapp.com",
    databaseURL: "https://sln-client-default-rtdb.firebaseio.com",
    projectId: "sln-client",
    storageBucket: "sln-client.appspot.com",
    messagingSenderId: "75685403325",
    appId: "1:75685403325:web:5d0dc35cb57f1d4df1f5d4",
    measurementId: "G-1KG0J67BXY"
  };

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig, 'secondary');
export const db = getFirestore(app);
export const auth = getAuth(app);