import React from "react";
import "./loading.css";

const Loading = () => {
  return (
    // <div style={{ height: "100vh" }}>
    //   <div className="spinnerContainer">
    //     <img src="assets/spinner.gif" alt="Loading..." />
    //   </div>
    // </div>
    <div className="loading">
      <span className="spinner spinner-dark"></span>
    </div>
  );
};

export default Loading;
