import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { getDocs, collection, query, where } from "firebase/firestore/lite";
import map from "lodash/map";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import GroupIntro from "./group_intro/GroupIntro";


function ProviderCard({ recommendations, tagList, location, date }) {
  const [tags, setTags] = useState();
  const [result, setResult] = useState([]);
  const [providers, setProviders] = useState([]);

  const haversineDistance = ([lat1, lon1], [lat2, lon2], isMiles = true) => {
    const toRadian = (angle) => (Math.PI / 180) * angle;
    const distance = (a, b) => (Math.PI / 180) * (a - b);
    const RADIUS_OF_EARTH_IN_KM = 6371;

    const dLat = distance(lat2, lat1);
    const dLon = distance(lon2, lon1);

    lat1 = toRadian(lat1);
    lat2 = toRadian(lat2);

    // Haversine Formula
    const a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.asin(Math.sqrt(a));

    let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

    if (isMiles) {
      finalDistance /= 1.60934;
    }
    console.log(finalDistance, "finaldistance");
    return finalDistance;
  };

  //  map recommendations to apply geocode api to each location
  //[function, function, function]
  useEffect(() => {
    (async () => {
      const result = await Promise.all(
        recommendations.map(async (provider) => {
          // async function fetchGeocode() {
          let response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${provider.address}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
          );
          let data = await response.json();
          console.log(data, 'data')
          let geocode = data.results[0].geometry.location;
          console.log(geocode, "providerslocs");
          const dist = haversineDistance(
            [location.lat, location.lng],
            [geocode.lat, geocode.lng]
          );
          return {
            dist,
            location: geocode,
            ...provider,
          };
        })
      );
      setProviders(sortBy(result, ["dist"]));
    })();
    // eslint-disable-next-line
  }, []);

  console.log(providers, 'what is going on')
  //  quering tags collection to find description
  useEffect(() => {
    async function get(db) {
      const tagsRef = collection(db, "tags");
      const q = query(tagsRef, where("tag", "in", tagList));
      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs.map((doc) => doc.data());
      return results;
    }

    const init = async () => {
      let results = await get(db);
      setTags(results);
    };
    init();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!tags || !tagList) return;
    const _result = map(tagList, (tag) => find(tags, ["tag", tag]));
    setResult(_result);
  }, [tags, tagList]);


  console.log(tagList, "tags");
  console.log(result, 'result');


  return (
    <main>
      <ul>
        {result?.map(({ tag, description }) => (
          <>
            <GroupIntro
              key={Math.random()}
              tag={tag}
              tags={tags}
              description={description}
              providers={providers}
              date={date}
            />
            <div className="page-break" />
          </>
        ))}
      </ul>
    </main>
  );
}
export default ProviderCard;
