import React from "react";
import { Link } from "react-router-dom";
import "./group_intro.css";
import Card from "../card/Card";


const GroupIntro = ({ tag, tags, description, providers, date }) => {
  let tagName = tag.charAt(0).toUpperCase() + tag.slice(1).replace("_", " ");
  let tagName_2 = tag.charAt(0) + tag.slice(1).replace("_", " ");

  let viewMoreUrl = `/view-more`

  return (
<>

    <li key={`section-${tag}`} className="group">
      <div className="group_intro_title">
        {tag === "care_coordination" ? (
          <h2>Don't want to do it all yourself?</h2>
        ) : (
          <h2>{tagName}</h2>
        )}
        <hr />
      </div>
      <div className="group_intro_description">
        <p>
          {description}
          <Link to={viewMoreUrl} state={{ data: date, tag: tag, description: description, tags: tags, providers: providers }}>{" "}View more {tagName_2} providers.</Link> 
        </p>
      </div>

      <Card providers={providers} tag={tag} tags={tags} />
    </li>
</>

  );
};

export default GroupIntro;
