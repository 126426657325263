import React from "react";
import "./card.css";

const ViewMoreCard = ({ providers, tag }) => {
  let index = 0;
  return (
    <div className="group_card_container">
      {providers
        .filter((provider) => provider.tags.includes(tag))
        .map((provider, key) => {

          function formatPhoneNumber(phoneNumber) {
            let cleaned = ("" + phoneNumber).replace(/\D/g, "");
            let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              return ["(", match[2], ") ", match[3], "-", match[4]].join("");
            }
            return null;
          }



          // let providerUrl = "https://scroll.care/provider-catalog/p/" + `${provider.slug}`;
          let providerUrl = `https://scroll.care/provider-catalog/p/${provider.slug}`;

          let source = "";

          if (provider.img2) {
            source = provider.img2;
          } else {
            source = "https://storage.googleapis.com/sln-client.appspot.com/assets/180x180.jpg"
          }

          index++;
          if (index === 7) {
            index = 1;
          }

          return (
            <>
              <div
                key={key}
                style={
                  tag === "care_coordination"
                    ? { background: "#fff", border: "0.75px solid #3DC0BB" }
                    : { background: "#e1e9f3" }
                }
                className="card"
              >
                {/* <img src={provider.logo} alt="logo" /> */}
                <div className="card_header">
                  <div className="card_header_logo">
                    <img src={source} alt="headshot" />
                    {/* <p>Photo</p> */}
                  </div>

                  <div className="card_header_creds">
                    <h3>{provider.companyName}</h3>
                    <p>{provider.address}</p>
                  </div>
                </div>

                <div className="card_contacts">
                  <p>{provider.email}</p>
                  <p>{formatPhoneNumber(provider.phone)}</p>
                </div>

                <div className="card_btn">
                  <a href={providerUrl}>View provider page</a>
                </div>
              </div>
              {index === 6 && <div className="page-break" />}
            </>
          );
        })}
    </div>

  );
};

export default ViewMoreCard;

